import React, { useEffect, useState } from "react";
import * as S from "./estilos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgItemProdutos1 from "../../Images/ItemProdutos1.png";
import ImgItemProdutos2 from "../../Images/ItemProdutos2.png";
import ImgItemProdutos3 from "../../Images/ItemProdutos3.png";
import ImgItemProdutos4 from "../../Images/ItemProdutos4.png";
import ImgItemProdutos5 from "../../Images/ItemProdutos5.png";
import ImgItemProdutos6 from "../../Images/ItemProdutos6.png";
import ImgItemProdutos7 from "../../Images/ItemProdutos7.png";

function Produtos() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>     
      <div style={{position: 'absolute', marginTop: '-140px'}} id="Produtos"></div>
      <S.BgProdutos>
        <div className="ForaProdutos">
          <h1>PRODUTOS</h1>
          <Slider className="SliderProdutos" {...settings}>
            <S.ItemProdutos>
              <img src={ImgItemProdutos7} />
              <h4>Gerador de Neblina para Alarme</h4>
            </S.ItemProdutos>                
            <S.ItemProdutos>
              <img src={ImgItemProdutos1} />
              <h4>Teclado em Lcd</h4>
            </S.ItemProdutos>            
            <S.ItemProdutos>
              <img src={ImgItemProdutos2} />
              <h4>Central de choque</h4>
            </S.ItemProdutos>            
            <S.ItemProdutos>
              <img src={ImgItemProdutos3} />
              <h4>Sistema de Alarme sem fio</h4>
            </S.ItemProdutos>            
            <S.ItemProdutos>
              <img src={ImgItemProdutos4} />
              <h4></h4>
            </S.ItemProdutos>            
            <S.ItemProdutos>
              <img src={ImgItemProdutos5} />
              <h4>Interfonia e Leitura Facial</h4>
            </S.ItemProdutos>            
            <S.ItemProdutos>
              <img src={ImgItemProdutos6} />
              <h4>Controle de Acesso</h4>
            </S.ItemProdutos>
          </Slider>
        </div>
      </S.BgProdutos>
    </>
  );
}

export default Produtos;
