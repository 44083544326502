/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Load from "../Load";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ImgLogo from "../../Images/Logo.png";
import IconRedes1 from "../../Images/IconRedes1.png";
import IconRedes2 from "../../Images/IconRedes2.png";
import IconRedes3 from "../../Images/IconRedes3.png";

import ImgBanner1 from "../../Images/Banner1.jpg";
import ImgBanner2 from "../../Images/Banner2.jpg";
import ImgBanner3 from "../../Images/Banner3.jpg";
import ImgBanner4 from "../../Images/Banner4.jpg";

import ImgBanner1Resp from "../../Images/Banner1Resp.jpg";
import ImgBanner2Resp from "../../Images/Banner2Resp.jpg";
import ImgBanner3Resp from "../../Images/Banner3Resp.jpg";
import ImgBanner4Resp from "../../Images/Banner4Resp.jpg";

function Header(props: any) {
  const history = useHistory();
  const [menu, setMenu] = useState(0);
  const [load, setLoad] = useState(false);
  const [dados, setDados] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 870,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <>
      {load == true && <Load status={true} />}
      <div id="Home"></div>
      <S.BgHeader>
        <section>
          <img className="ImgLogo" alt="Logo" src={ImgLogo} />
          <S.ForaMenu>
            <a href="#Home">Home</a>
            <a href="#Servicos">Serviços</a>
            <a href="#Produtos">Produtos</a>
            <a href="#Contato">Contato</a>
            <a href="#Clientes">Clientes</a>
          </S.ForaMenu>
          <S.ForaRedes>
            <a style={{height: '28px'}} target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61558639294840">
              <img alt="Ícone Facebook" src={IconRedes1} />
            </a>
            <a style={{height: '28px'}} target="_blank" rel="noreferrer" href="https://www.instagram.com/salesmonitoramento/?igsh=OWtvazVtbHloeHBj">
              <img alt="Ícone Instagram" src={IconRedes2} />
            </a>
            <a style={{height: '28px'}} target="_blank" rel="noreferrer" href="https://wa.me/5511959758871">
              <img alt="Ícone Whats App" src={IconRedes3} />
            </a>            
          </S.ForaRedes>
          <S.ForaBotao onClick={() => setMenu(1)}>
            <div />
            <div />
            <div />
          </S.ForaBotao>
        </section>
      </S.BgHeader>

      {menu == 1 && (
        <S.BgMenuMobile>
          <div onClick={() => setMenu(0)} className="XMobile">
            X
          </div>
          <div className="ForaMenuMobile">
            <h2>Sales Monitoramento</h2>
            <S.ItensMenuMobile>
              <a onClick={() => setMenu(0)} href="#Home">
                Home
              </a>
              <a onClick={() => setMenu(0)} href="#Servicos">
                Serviços
              </a>
              <a onClick={() => setMenu(0)} href="#Produtos">
                Produtos
              </a>
              <a onClick={() => setMenu(0)} href="#Contato">
                Contato
              </a>  
              <a onClick={() => setMenu(0)} href="#Clientes">
                Clientes
              </a>            
            </S.ItensMenuMobile>
            <S.ForaRedesMenuMobile>
              <a target="_blank" rel="noreferrer" href="">
                <img src={IconRedes1} />
              </a>
              <a target="_blank" rel="noreferrer" href="">
                <img src={IconRedes2} />
              </a>
              <a target="_blank" rel="noreferrer" href="">
                <img src={IconRedes3} />
              </a>
            </S.ForaRedesMenuMobile>
          </div>
        </S.BgMenuMobile>
      )}

      <S.ForaBanner>
        <Slider className="SliderBanner1" {...settings}>
          <img src={ImgBanner1} />
          <img src={ImgBanner2} />
          <img src={ImgBanner3} />
          <img src={ImgBanner4} />
        </Slider>
      </S.ForaBanner>

      <S.ForaBannerResp>
        <Slider className="SliderBanner1" {...settings}>
          <img src={ImgBanner1Resp} />
          <img src={ImgBanner2Resp} />
          <img src={ImgBanner3Resp} />
          <img src={ImgBanner4Resp} />
        </Slider>
      </S.ForaBannerResp>
    </>
  );
}

export default withRouter(Header);
