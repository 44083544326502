import React, { useEffect, useState } from "react";
import * as S from "./estilos";

import ImgServicos1 from "../../Images/ImgServicos1.png";
import ImgServicos2 from "../../Images/ImgServicos2.png";
import ImgServicos3 from "../../Images/ImgServicos3.png";
import ImgServicos4 from "../../Images/ImgServicos4.png";
import ImgServicos5 from "../../Images/ImgServicos5.png";
import ImgServicos6 from "../../Images/ImgServicos6.png";



function Servicos() {

  return (
    <>     
      <div style={{position: 'absolute', marginTop: '-140px'}} id="Servicos"></div>
      <S.BgServicos>
        <div className="ForaServicos">
          <h1>SERVIÇOS</h1>

          <div className="ForaItensServicos">
            <S.ItemServicos>
              <img src={ImgServicos1} />
              <h4>Câmeras</h4>
              <p>Instalação de câmeras de monitoramento com acesso via aplicativo</p>
            </S.ItemServicos>            
            <S.ItemServicos>
              <img src={ImgServicos2} />
              <h4>Alarmes</h4>
              <p>Instalação de larmes com ativação / desativação via aplicativo</p>
            </S.ItemServicos>            
            <S.ItemServicos>
              <img src={ImgServicos3} />
              <h4>Cercas</h4>
              <p>Instalação de cerca elétrica</p>
            </S.ItemServicos>            
            <S.ItemServicos>
              <img src={ImgServicos4} />
              <h4>Locações</h4>
              <p>Locação de sistema de câmeras e alarmes</p>
            </S.ItemServicos>            
            <S.ItemServicos>
              <img src={ImgServicos5} />
              <h4>Rede</h4>
              <p></p>
            </S.ItemServicos>            
            <S.ItemServicos>
              <img src={ImgServicos6} />
              <h4>Intefone</h4>
              <p></p>
            </S.ItemServicos>
          </div>
          
        </div>
      </S.BgServicos>
    </>
  );
}

export default Servicos;
