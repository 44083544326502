import React, { useEffect, useState } from "react";
import * as S from "./estilos";
import useData from "../../Utils/useData";

import LogoFooter from "../../Images/LogoFooter.png";
import IconRedes1 from "../../Images/IconRedes1.png";
import IconRedes2 from "../../Images/IconRedes2.png";
import IconRedes3 from "../../Images/IconRedes3.png";


function Footer() {

  return (
    <>     
      <S.BgFooter>
        <div className="ForaFooter">
          <S.CimaFooter>
            <img src={LogoFooter} />
            <div className="MenuFooter">
              <a href="#Home">Home</a>
              <a href="#Servicos">Serviços</a>
              <a href="#Produtos">Produtos</a>
              <a href="#Contato">Contato</a>
              <a href="#Clientes">Clientes</a>
            </div>
            <div className="ForaIconsFooter">
            <a style={{height: '28px'}} target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61558639294840">
              <img alt="Ícone Facebook" src={IconRedes1} />
            </a>
            <a style={{height: '28px'}} target="_blank" rel="noreferrer" href="https://www.instagram.com/salesmonitoramento/?igsh=OWtvazVtbHloeHBj">
              <img alt="Ícone Instagram" src={IconRedes2} />
            </a>
            <a style={{height: '28px'}} target="_blank" rel="noreferrer" href="https://wa.me/5511959758871">
              <img alt="Ícone Whats App" src={IconRedes3} />
            </a>   
            </div>
          </S.CimaFooter>
          <S.BaixoFooter>
            <p>
              Desde 2008 no mercado de soluções em segurança eletrônica, passamos por várias mudanças de tecnologias, comportamentos, crises econômicas. Mas a nossa capacidade de adaptação, constante aprimoramento tecnológico e principalmente, capacitação de pessoas para os novos tempos é que fazem com que a Sales Monitoramento consiga oferecer serviços e produtos de qualidade a preços competitivos em Portaria Remota, Alarmes monitorados 24h, Câmeras, Monitoramento Remoto de Imagens, e Controle de acesso de pessoas.
              <br /><br />
              Nossa experiência nos permite fazer projetos personalizados e eficientes para cada cliente, com a melhor tecnologia disponível, certamente temos uma solução em segurança eletrônica que vai te atender em qualidade e estar dentro do seu orçamento.
            </p>
            <div className="ForaTextoBaixo">
              <h2>11 95975-8871</h2>
              <span>contato@salesmonitoramento.com.br</span>
            </div>
          </S.BaixoFooter>
        </div>
      </S.BgFooter>     
    </>
  );
}

export default Footer;
