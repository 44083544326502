import styled from 'styled-components';

export const ForaClientes = styled.div`
    width: 1050px;
    margin: 0 auto;
    padding-bottom: 60px;

    .ImgProdutos {
        width: 76% !important;
        padding-left: 16%;
    }

    h2 {
        color: #0075be;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        font-size: 29px;
        padding-top: 55px;
        padding-bottom: 35px;
    }

    @media (max-width: 1220px) {
        width: 90%;
    }   

    @media (max-width: 650px) {
        padding-bottom: 40px;

        h2 {
            padding-top: 45px;
            padding-bottom: 10px;
        }
    }   
`;

