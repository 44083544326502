import styled from 'styled-components';
// export const BackgroundHeader = styled.header`
//   background-color: ${({ theme }) => theme.color.black};
//   background-color: ${({ theme }) => theme.color.primary};
//   position: fixed;
//   width: 100%;
//   z-index: 2;
// `;

export const BgHeader = styled.header`
    height: 100px;
    display: flex;
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 125px;
    background-color: #fff;

    section {
        display: flex;
        width: 1400px;
        margin: 0 auto;
        align-items: center;
        justify-content: space-between;
    }

    @media (max-width: 1460px) {
        section {
            width: 90%;
        }
    }    
    
    @media (max-width: 1140px) {
        img {
            width: 150px;
        }
    }    
    
    @media (max-width: 850px) {
        height: 85px;
    }

`;

export const ForaMenu = styled.div`
    display: flex;
    width: 64%;
    justify-content: space-between;
    height: 26px;
    
    a {
        color: #0075be;
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
    }

    a:hover {
        color: #014189;
    }

    @media (max-width: 1140px) {
        width: 59%;

        a {
            font-size: 18px;
        }
    }    
    
    @media (max-width: 850px) {
        display: none;
    }
`;

export const ForaRedes = styled.div`
    display: flex;
    width: 110px;
    justify-content: space-between;

    img {
        width: 28px;
        cursor: pointer;
    }
`;

export const ForaBotao = styled.div`
    display: none;
    flex-direction: column;
    height: 32px;
    justify-content: space-between;
    width: 45px;
    cursor: pointer;

    div {
        background-color: #014189;
        height: 4px;
        width: 100%;
        border-radius: 2px;
    }

    @media (max-width: 850px) {
        display: flex;
    }
`;


export const BgMenuMobile = styled.div`
    width: 100%;
    height: 100%;
    background-color: #014189;
    opacity: 0.94;
    position: fixed;
    z-index: 1001;
    display: flex;
    align-items: center;

    .XMobile {
        font-weight: bold;
        position: absolute;
        top: 25px;
        right: 25px;
        font-size: 25px;
        cursor: pointer;
        color: #fff;
    }

    .ForaMenuMobile {
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h2 {
        color: #fff;
        text-transform: uppercase;
        font-size: 35px;
        margin-bottom: 20px;
        text-align: center;
    }

    @media (max-width: 600px) {
        h2 {
            font-size: 31px;
            line-height: 35px;
        }
    }       
`;

export const ItensMenuMobile = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        color: #fff;
        text-decoration: none;
        font-weight: bold;
        font-size: 30px;
        padding-top: 10px;
        cursor: pointer;
    }
`;

export const ForaRedesMenuMobile = styled.div`
    width: 200px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 40px;
        height: 40px;
    }
`;


export const ForaBanner = styled.div`
    padding-top: 125px;

    a {
        text-decoration: none !important;
    }

    div {
    }

    .slick-slide img {
        width: 100%;
    }

    @media (max-width: 850px) {
        padding-top: 85px;
    }       
    
    @media (max-width: 800px) {
        display: none;
    }   
`;

export const ForaBannerResp = styled.div`
    display: none;
    padding-top: 85px;

    div {
    }

    .slick-slide img {
        width: 100%;
    }

    @media (max-width: 800px) {
        display: flex;
    }   
`;
