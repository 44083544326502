import React, { useEffect, useState } from "react";
import * as S from "./estilos";

import Load from "../Load";

import { Alert } from "../atoms";

function SoliciteOrcamento() {
  const [load, setLoad] = useState(false);
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [servico, setServico] = useState("");
  const [descricao, setDescricao] = useState("");

  const clearFields = () => {
    setNome("");
    setCelular("");
    setEmail("");
    setServico("");
    setDescricao("");
  };

  const onSubmit = () => {
    let validaMenssages = [];
    validaMenssages.push(<div style={{ marginBottom: "10px" }}>Atenção, segue abaixo campos obrigatórios:</div>);
    let valida = false;

    if (nome === "" || nome === undefined) {
      validaMenssages.push(<div key={"Nome"}>- Nome</div>);
      valida = true;
    }
    if (email === "" || email === undefined) {
      validaMenssages.push(<div key={"E-mail"}>- E-mail</div>);
      valida = true;
    } else {
      if (
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email,
        )
      ) {
        null;
      } else {
        validaMenssages.push(<div key={"E-mail"}>- E-mail (inválido)</div>);
        valida = true;
      }
    }

    if (celular === "" || celular === undefined) {
      validaMenssages.push(<div key={"Telefone"}>- Telefone</div>);
      valida = true;
    }
    if (servico === "" || servico === undefined) {
      validaMenssages.push(<div key={"Servico"}>- Serviço</div>);
      valida = true;
    }    
    if (descricao === "" || descricao === undefined) {
      validaMenssages.push(<div key={"Descricao"}>- Descrição</div>);
      valida = true;
    }
    if (valida) {
      Alert(<div>{validaMenssages}</div>);
      console.log("Valida");
      console.log(validaMenssages[0]);
    } else {
      setLoad(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        Nome: nome,
        Celular: celular,
        Email: email,
        Servico: servico,
        Descricao: descricao
      });

      validaMenssages = [];

      fetch("https://salesmonitoramento.com.br/api/envia_contato.php", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      })
        .then((response) => response.text())
        .then((response: any) => {
          // console.log(response);
          setLoad(false);
          Alert(
            <>
              <div>Formulário de contato enviado com sucesso!</div>
              <div>Em breve entraremos em contato.</div>
            </>,
          );
          clearFields();
          validaMenssages = [];
          // setNome('');
          // setEmail('');
          // setCelular('');
          // setMensagem('');
          setLoad(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <>  
      {load == true && <Load status={true} />}
      <div style={{position: 'absolute', marginTop: '-140px'}} id="Contato"></div>   
      <S.BgSolicite>
        <div className="ForaSolicite">
          <h1>Solicite Orçamento</h1>
          <h3>Todos os campos do formulário são obrigatórios.</h3>
          <form> 
            <input type="text" placeholder="Nome" value={nome} onChange={(e) => setNome(e.target.value)} name="nome" id="nome" />
            <input type="text" placeholder="E-mail" value={email} onChange={(e) => setEmail(e.target.value)} name="c_email" id="email" />
            <input type="text" placeholder="Celular" value={celular} onChange={(e) => setCelular(e.target.value)} name="telefone"  id="telefone" />
            <input type="text" placeholder="Serviço" value={servico} onChange={(e) => setServico(e.target.value)} name="servico" id="servico" />
            <input type="text" placeholder="Descrição" value={descricao} onChange={(e) => setDescricao(e.target.value)} name="descricao" id="descricao" />
          </form>
          <button onClick={() => onSubmit()}>Enviar mensagem</button>
        </div>
      </S.BgSolicite>
    </>
  );
}

export default SoliciteOrcamento;
