/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import * as S from "./estilos";

import Load from "../Load";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ItemCliente1 from "../../Images/ItemCliente1.jpg";
import ItemCliente2 from "../../Images/ItemCliente2.jpg";
import ItemCliente3 from "../../Images/ItemCliente3.jpg";
import ItemCliente4 from "../../Images/ItemCliente4.jpg";
import ItemCliente5 from "../../Images/ItemCliente5.jpg";
import ItemCliente6 from "../../Images/ItemCliente6.jpg";

function Clientes(props: any) {
  const history = useHistory();
  const [menu, setMenu] = useState(0);
  const [load, setLoad] = useState(false);
  const [dados, setDados] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 5,

    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },      
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },      
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };


  return (
    <>
      {load == true && <Load status={true} />}
      <div style={{position: 'absolute', marginTop: '-140px'}} id="Clientes"></div>
      <S.ForaClientes id="Home">
        <h2>Nossos Clientes</h2>
        <Slider className="SliderClientes" {...settings}>
          <img className="ImgProdutos" src={ItemCliente1} />
          <img className="ImgProdutos" src={ItemCliente2} />
          <img className="ImgProdutos" src={ItemCliente3} />
          <img className="ImgProdutos" src={ItemCliente4} />
          <img className="ImgProdutos" src={ItemCliente5} />
          <img className="ImgProdutos" src={ItemCliente6} />
        </Slider>
      </S.ForaClientes>
    </>
  );
}

export default withRouter(Clientes);
