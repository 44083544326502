import styled from 'styled-components';
// import BgRodape from "../../Images/BgRodape.jpg";

export const BgFooter = styled.footer`
  background-color: #014189;

  .ForaFooter {
    width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 1080px) {
    .ForaFooter {
      width: 88%;
    }
  }   
`;

export const CimaFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .MenuFooter {
    width: 56%;
    display: flex;
    justify-content: space-between;

    a {
      font-weight: 600;
      font-size: 15px;
      text-transform: uppercase;
    }
  }

  .ForaIconsFooter {
    width: 120px;
    display: flex;
    justify-content: space-between;

    img {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 1080px) {
    .MenuFooter {
      display: none;
    }
  }   
`;

export const BaixoFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;

  p {
    font-size: 12px;
    font-weight: 400;
    width: 71%;
  }

  .ForaTextoBaixo {
    h2 {
      font-weight: 900;
      font-size: 22px;
      text-transform: uppercase;
    }

    span {
      font-weight: 300;
      font-size: 12px;
    }
  }

  @media (max-width: 1080px) {
    p {
      width: 64%;
    }
  }     
  
  @media (max-width: 840px) {
    flex-direction: column;

    p {
      width: 100%;
    }
  }   
`;
