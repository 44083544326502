import styled from 'styled-components';
import ImgBgServicos from "../../Images/BgServicos.jpg";

export const BgServicos = styled.section`
  background-image: url(${ImgBgServicos});
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .ForaServicos {
    width: 1020px;
  }

  h1 {
    font-size: 50px;
    color: #014189;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    padding-top: 4vw;
    padding-bottom: 3vw;
  }

  .ForaItensServicos {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4vw;
    flex-wrap: wrap;
    margin-top: -15px;
  }

  @media (max-width: 1100px) {
    .ForaServicos {
      width: 710px;
    }
  }    
  
  @media (max-width: 750px) {
    background-image: none;
    background-color: #f1f1f1;

    .ForaServicos {
      width: 90%;
    }

    h1 {
      font-size: 40px;
    }

    .ForaItensServicos {
      flex-wrap: wrap;
    }
  }  

  @media (max-width: 650px) {
      h1 {
        font-size: 32px;
      }
  }  

`;

export const ItemServicos = styled.div`
  width: 24%;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    font-size: 20px;
    font-weight: 900;
    color: #014189;
    font-style: italic;
    padding-top: 20px;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    color: #777777;
    text-align: center;
    margin: 0px;
  }

  @media (max-width: 1100px) {
    img {
      width: 140px;
    }
  }  

  @media (max-width: 750px) {
    width: 48%;
    margin-top: 15px;

    h4 {
      padding-top: 5px;
    }
  }    
  
  @media (max-width: 500px) {
    img {
      width: 115px;
    }
  }  
`;
