import React, { useEffect, useState } from "react";
import * as S from "./estilos";

import IconRedes1 from "../../Images/IconRedes1.png";
import IconRedes2 from "../../Images/IconRedes2.png";
import IconRedes3 from "../../Images/IconRedes3.png";


function Siga() {

  return (
    <>     
      <S.BgSiga>
        <div className="ForaSiga">
          <h3>Siga-nos</h3>
          <div className="ForaRedes">
            <a target="_blank" rel="noreferrer" href="https://www.facebook.com/profile.php?id=61558639294840">
              <img src={IconRedes1} />
            </a>
            <a target="_blank" rel="noreferrer" href="https://www.instagram.com/salesmonitoramento/?igsh=OWtvazVtbHloeHBj">
              <img src={IconRedes2} />
            </a>
          </div>
        </div>
      </S.BgSiga>     
    </>
  );
}

export default Siga;
