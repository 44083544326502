import styled from 'styled-components';


export const BgSiga = styled.section`
  .ForaSiga {
    display: flex;
    width: 345px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding-top: 45px;
    padding-bottom: 45px;

    h3 {
      color: #0075be;
      font-size: 29px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .ForaRedes {
      display: flex;
      justify-content: space-between;
      width: 139px;
    }

  }

  @media (max-width: 900px) {
    .ForaSiga {
      padding-top: 25px;
      padding-bottom: 25px;
      width: 305px;
    }
  }  

`;
