import styled from 'styled-components';
import ImgBgProdutos from "../../Images/BgProdutos.jpg";

export const BgProdutos = styled.section`
  background-image: url(${ImgBgProdutos});
  background-size: cover;
  background-position: center;
  width: 100%;

  .ForaProdutos {
    display: flex;
    flex-direction: column;
    width: 930px;
    margin: 0 auto;
    padding-bottom: 4vw;

    h1 {
      font-size: 50px;
      color: #014189;
      text-transform: uppercase;
      text-align: center;
      font-weight: 900;
      padding-top: 4vw;
      padding-bottom: 3vw;
    }
  }

  @media (max-width: 1350px) {
    .ForaProdutos {
      width: 69%;

      h1 {
        font-size: 40px;
      }
    }
  }    
  
  @media (max-width: 650px) {
    .ForaProdutos {
      width: 72%;
      padding-bottom: 30px;
      padding-top: 15px;

      h1 {
        font-size: 32px;
      }

      img {
        width: 100%;
      }
    }
  }  
`;

export const ItemProdutos = styled.div`
  h4 {
    font-size: 20px;
    font-weight: 600;
    color: #777777;
    text-transform: uppercase;
    text-align: center;
    margin-top: 3vw;
  }
`;
