import styled from 'styled-components';

export const BgSolicite = styled.section`
  background-color: #e2e2e2;
  text-align: center;

  .ForaSolicite {
    width: 840px;
    margin: 0 auto;
  }

  h1 {
    font-size: 50px;
    color: #014189;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    padding-top: 70px;
    padding-bottom: 20px;
  }

  h3 {
    color: #777777;
    font-size: 15px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }

  input {
    height: 55px;
    background-color: #fff;
    margin-top: 8px;
    border: 0px;
    border-radius: 8px;
    padding-left: 20px;
    width: calc(100% - 20px);
    padding-left: 20px;
    font-weight: bold;
    font-size: 15px;
  }

  button {
    background-color: #0159a1;
    width: 350px;
    height: 55px;
    border: 0px;
    color: #fff;
    font-size: 15px;
    font-style: italic;
    font-weight: 900;
    text-transform: uppercase;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 75px;
  }

  @media (max-width: 1350px) {
    h1 {
      font-size: 40px;
    }
  }      
  
  @media (max-width: 900px) {
    .ForaSolicite {
      width: 90%;
      margin: 0 auto;
    }

    h1 {
      padding-top: 30px;
      padding-bottom: 15px;
    }

    button {
      margin-bottom: 40px;
    }
  }     
  
  @media (max-width: 650px) {
    h1 {
      font-size: 32px;
    }

    input {
      height: 45px;
    }

    button {
      height: 45px;
      width: 100%;
    }
  }      
  
  @media (max-width: 500px) {
    h1 {
      width: 82%;
      text-align: center;
      line-height: 34px;
      margin: 0 auto;
    }
  }    
`;
